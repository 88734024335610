import Link from 'next/link';
import { useIntl } from 'react-intl';
import type { NextPageWithLayout } from './_app';

type NotFoundPageProps = {};

const NotFoundPage: NextPageWithLayout<NotFoundPageProps> = () => {
  const intl = useIntl();

  return (
    <div className='min-h-full px-6 py-16 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          <p className='text-primary text-4xl font-bold tracking-tight sm:text-5xl'>404</p>
          <div className='sm:ml-6'>
            <div className='sm:border-surface-divid sm:border-l sm:pl-6'>
              <h1 className='text-content-focus text-4xl font-bold tracking-tight sm:text-5xl'>
                {intl.formatMessage({
                  defaultMessage: 'Stránka nenalezena',
                  description: 'Page not found page title',
                  id: 'zwh0ID',
                })}
              </h1>
              <p className='text-root-supress mt-1 text-base'>
                {intl.formatMessage({
                  defaultMessage: 'Prosím zkontrolujte URL v adresním řádku a zkuste znovu.',
                  description: 'Please check the URL in the address bar and try again.',
                  id: 'D+4DH/',
                })}
              </p>
            </div>
            <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <Link className='btn btn-primary' href='/'>
                {intl.formatMessage({
                  defaultMessage: 'Zpět na domovskou stránku',
                  description: 'Go back home action lable',
                  id: '5u2D2F',
                })}
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
